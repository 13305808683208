import Vue from 'vue'
import locale from 'element-ui/lib/locale';
import VueI18n from 'vue-i18n'
import store from '../store';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element中文语言包
import enLocale from 'element-ui/lib/locale/lang/en' //引入element英文语言包
import thLocale from 'element-ui/lib/locale/lang/th' //引入element泰语语言包

import {cn as globalCn, en as globalEn, th as globalTh} from './langs/global'
import {cn as loginCn, en as loginEn} from './langs/login'
import {cn as indexCn, en as indexEn} from './langs/index'
import {cn as systemCn, en as systemEn} from './langs/system'
import {cn as userCn, en as userEn} from './langs/user'
import {cn as languageCn, en as languageEn} from './langs/language'
import {cn as noticeCn, en as noticeEn} from './langs/notice'
import {cn as flashCn, en as flashEn} from './langs/flash'
import {cn as gradeCn, en as gradeEn} from './langs/grade'
import {cn as memberCn, en as memberEn, th as memberTh} from './langs/member'
import {cn as settingCn, en as settingEn} from './langs/setting'
import {cn as iplistCn, en as iplistEn} from './langs/iplist'
import {cn as memberBankCn, en as memberBankEn} from './langs/memberBank'
import {cn as shareLinkCn, en as shareLinkEn} from './langs/shareLink'
import {cn as agentCn, en as agentEn} from './langs/agent'
import {cn as leaderboardCn, en as leaderboardEn} from './langs/leaderboard'
import {cn as fundlogsCn, en as fundlogsEn, th as fundlogsTh} from './langs/fundlogs'
import {cn as onebuyCn, en as onebuyEn, th as onebuyTh} from './langs/onebuy'
import {cn as shortLinkCn, en as shortLinkEn} from './langs/shortLink'


Vue.use(VueI18n)

let CN = {
    global: globalCn,
    login: loginCn,
    index: indexCn,
    system: systemCn,
    user: userCn,
    language: languageCn,
    notice: noticeCn,
    flash: flashCn,
    grade: gradeCn,
    member: memberCn,
    setting: settingCn,
    iplist: iplistCn,
    memberbank: memberBankCn,
    sharelink: shareLinkCn,
    agent: agentCn,
    leaderboard: leaderboardCn,
    fundlogs: fundlogsCn,
    onebuy: onebuyCn,
    shortLink: shortLinkCn,
    ...zhLocale
}

let EN = {
    global: globalEn,
    login: loginEn,
    index: indexEn,
    system: systemEn,
    user: userEn,
    language: languageEn,
    notice: noticeEn,
    flash: flashEn,
    grade: gradeEn,
    member: memberEn,
    setting: settingEn,
    iplist: iplistEn,
    memberbank: memberBankEn,
    sharelink: shareLinkEn,
    agent: agentEn,
    leaderboard: leaderboardEn,
    fundlogs: fundlogsEn,
    onebuy: onebuyEn,
    shortLink: shortLinkEn,
    ...enLocale
}

let TH = {
    global: globalTh,
    login: loginEn,
    index: indexEn,
    system: systemEn,
    user: userEn,
    language: languageEn,
    notice: noticeEn,
    flash: flashEn,
    grade: gradeEn,
    member: memberTh,
    setting: settingEn,
    iplist: iplistEn,
    memberbank: memberBankEn,
    sharelink: shareLinkEn,
    agent: agentEn,
    leaderboard: leaderboardEn,
    fundlogs: fundlogsTh,
    onebuy: onebuyTh,
    ...thLocale
}

//从localStorage获取语言选择。
const i18n = new VueI18n({
    locale: store.getters.getCurrLang || 'cn', //初始未选择默认 cn 中文
    messages: {
        en: EN,
        cn: CN,
        th: TH,
    },
    silentFallbackWarn: true,
    silentTranslationWarn: true,
})
locale.i18n((key, value) => i18n.t(key, value)) //兼容elemTht

export default i18n