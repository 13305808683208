export const en = {
    addFlash: 'Add Grade',
    editFlash: 'Edit Grade',
    title: 'Title',
    grade_name:'Grade',
    grade: 'Grade',
    language: 'Language',
    chooselanguage: 'Please Choose Language',
    buy_price: 'Old Price',
    sale_price: 'Active Price',
    task_times: 'Task Times',
    percent: 'Percent',
    rake_back: 'Rake Back',
    sort: 'Sort',
    status: 'Status',
    created_at: 'Create Time',
}

export const cn = {
    addFlash: '添加等级',
    editFlash: '编辑等级',
    title: '等级名称',
    grade_name:'等级',
    grade: '会员等级',
    language: '语言',
    chooselanguage: '请选择语言',
    buy_price: '原价',
    sale_price: '活动价格',
    task_times: '任务次数',
    percent: '抽成',
    rake_back: '推荐返佣',
    rake_back_placeholder:'如直推，间推，间间推 20,10,5',
    sort: '显示顺序',
    status: '显示状态',
    created_at: '添加时间',
}