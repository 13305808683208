import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import home from '@/views/home.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    redirect: '/home',
    component: index,
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/system/node',
        name: 'node',
        component: () => import(/* webpackChunkName: "node" */ '@/views/system/node.vue')
      },
      {
        path: '/system/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '@/views/system/menu.vue')
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '@/views/system/user.vue')
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '@/views/system/role.vue')
      },
      {
        path: '/system/department',
        name: 'department',
        component: () => import(/* webpackChunkName: "department" */ '@/views/system/department.vue')
      },
      {
        path: '/system/optlog',
        name: 'optlog',
        component: () => import(/* webpackChunkName: "optlog" */ '@/views/system/optlog.vue')
      },
      {
        path: '/system/language',
        name: 'language',
        component: () => import(/* webpackChunkName: "systemlanguage" */ '@/views/system/language.vue')
      },
      {
        path: '/system/notice_category',
        name: 'notice_category',
        component: () => import(/* webpackChunkName: "notice_category" */ '@/views/system/notice_category.vue')
      },
      {
        path: '/system/notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: "systemnotice" */ '@/views/system/notice.vue')
      },
      {
        path: '/system/flash',
        name: 'flash',
        component: () => import(/* webpackChunkName: "systemflash" */ '@/views/system/flash.vue')
      },
      {
        path: '/system/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/system/setting.vue')
      },
      {
        path: '/system/iplist',
        name: 'iplist',
        component: () => import(/* webpackChunkName: "systemiplist" */ '@/views/system/iplist.vue')
      },
      {
        path: '/link/list',
        name: 'link',
        component: () => import(/* webpackChunkName: "user" */ '@/views/link/list.vue')
      }


    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
