export const en = {
    add_link: 'Add ShortLink',
    edit_link: 'Edit ShortLink',
    short_url: 'Split link',
    add_domain: 'Add redirect domain name',
    type: 'Type',
}

export const cn = {
    add_link: '添加短链接',
    edit_link: '编辑短链接',
    short_url: '分流链接',
    add_domain: '添加跳转域名',
    type: '类型',

}