export const th = {
	refreshtime: 'เวลารีเฟชร',
	getrealname: 'ชื่อผู้รับ',
	goodsname: 'ชื่อสินค้า',
	gosearch: 'ค้นหา',
	seconds:'วินาที',
	priceforeverytimes: 'ราคา(ต่อครั้ง/ราคารวม)',
	totalnumber: 'จำนวน(ซื้อแล้ว/ทั้งหมด)',
	begintimes: 'เวลาที่เริ่ม',
	isover: 'เสร็จสิ้นแล้ว',
	isnotover: 'ยังไม่เสร็จสิ้น',
	goover:'เสร็จสิ้น',
	cornsno: 'หมายเลข',
	username: 'ชื่อผู้โชคดี',
	createdtime: 'เวลาที่ซื้อ',
	isprezzy: 'ชนะหรือไม่',
	isnotlucky: 'ไม่ชนะ',
	islucky: 'ชนะ',
	goodstitle: 'หัวข้ิอ',
	language: 'ภาษา',
	begin_time: 'วันที่เริ่ม',
	begin_end: 'ถึง',
	end_time: 'วันที่จบ',
	status:'สถานะ',
	enabled:'ปกติ',
	disabled:'ซ่อน',
	addgoods:'เพิ่มสินค้า',
	goodsid:'หมายเลข',
	isuse:'รอบ',
	goodsicon:'ไอคอน',
	goodsprice:'ราคา(ต่อหน่วย/ราคารวม)',
	eachnum:'แต่ละคนสามารถซื้อได้ (ชิ้น)',
	opentime:'เวลาที่จับรางวัล',
 	isshow:'แสดง',
	 gopulish:'เปิดตัวกิจกรรม',
	isshelf:'เปิดตัวกิจกรรมแล้ว',
	push_now:'เปิดตอนนี้',
	createdat:'เพิ่มเวลา',
	opencode:'หมายเลขจับรางวัล',
	editgoods:'เปลี่ยนแปลงสินค้า',
	goods_title:'หัวข้อสินค้า',
	goods_desc:'แก้ไขสินค้า',
	total_price:'ราคารวม',
	pet_price:'ราคาต่อหน่วย',
	each_num:'จำกัดการซื้อต่อท่าน',
	total_num:'จำนวนทั้งหมด',
	lower_grade:'ระดับต่ำที่สุด',
	choosetime:'เลือกวันและเวลา',
	goods_img:'แสดงภาพขนาดย่อ',
	goods_pics:'รายการภาพ',
	click_uploads:'คลิกเพื่ออัพโหลด',
	uploads_explain:'ต้องเป็นไฟล์ jpg/png,ห้ามเกินขนาด 500kb',
	last_week:'สัปดาห์ที่ผ่านมา',
	last_month:'เดือนที่ผ่านมา',
	last_three_month:'3 เดือนที่ผ่านมา',
	shelf_warning:'หากกดยืนยันจะไม่สามารถเปลี่ยนแปลงข้อมูลสินค้าได้ ยืนยัน ?',
	suretoremove:'ยืนยันการยกเลิก',
	action:'ดำเนินการ',
	edit:'แก้ไข',
	delete:'ลบ',
	live_url:'ลิ้งก์การถ่ายทอดสด',
	cancel:'ยกเลิก',
	submit:'ส่ง',
	dkj:'กำลังจับรางวัล',
	ykj:'จับรางวัลแล้ว',
	wzj:'ยังไม่ได้จับรางวัล',
	isgetself:'รับด้วยตนเองหรือไม่',
	getmyself:'รับด้วยตนเอง',
	getbyposter:'ส่งไปรษณีย์',
	ordersn:'หมายเลขออเดอร์',
	buynumber:'จำนวนการสั่งซื้อ',
	waitpay:'ยังไม่ได้ชำระ',
	waitsend:'ชำระแล้ว',
	alreadysend:'จัดส่งของแล้ว',
	complated:'เสร็จสมบูรณ์',
	showaddr:'ที่อยู่',
	sendgoods:'ส่งสินค้า',
	getgoods:'รับด้วยตนเอง',
	send_c_msg:'ข้อมูลการส่งสินค้า',
	send_c_name:'บริษัทจัดส่งสินค้า',
	send_c_code:'หมายเลขการส่งสินค้า',
	send_c_tel:'เบอร์โทรศัพท์บริษัทจัดส่งสินค้า',
	order_addr:'ที่อยู่จัดส่ง',
	copy:'คัดลอก',
	suregetandcomplete:'ยืนยันการได้รับสินค้าในเวลาที่กำหนด?',
	cronscode:'หมายเลขสินค้า',
	input_get_code:'กรอกรหัสชนะ',
	get_code:'รหัสที่ชนะ',
	obsetting: 'ติดตั้ง',
}


export const en = {
	refreshtime: 'Refresh Time',
	getrealname: 'Name',
	goodsname: 'Product',
	gosearch: 'Search',
	seconds:'Second',
	priceforeverytimes: 'Price (Time/Total)',
	totalnumber: 'Quantity (Purchased/Total)',
	begintimes: 'Start Time',
	isover: 'Completed',
	isnotover: 'Not Completed',
	goover:'Ended',
	cornsno: 'LD ID',
	username: 'Username',
	createdtime: 'Purchase Time',
	isprezzy: 'Win Or Not',
	isnotlucky: 'Not Win Yet',
	islucky: 'Won',
	goodstitle: 'Title',
	language: 'Language',
	begin_time: 'Start Date',
	begin_end: 'Till',
	end_time: 'End Date',
	status:'Status',
	enabled:'Normal',
	disabled:'Disable',
	addgoods:'Add Product',
	goodsid:'No.',
	isuse:'Round No.',
	goodsicon:'Icon',
	goodsprice:'Price (Unit/Total)',
	eachnum:'Each Pax Purchase(Qty)',
	opentime:'Draw Time',
	isshow:'Display',
	gopulish:'Publish Event',
	isshelf:'Published',
	push_now:'Publish Now',
	createdat:'Date Created',
	opencode:'Winning Code',
	editgoods:'Edit',
	goods_title:'Product Title',
	goods_desc:'Product Description',
	total_price:'Total Price',
	pet_price:'Unit Price',
	each_num:'Purchase Limit Per Pax',
	total_num:'Total Amount',
	lower_grade:'Lowest Level',
	choosetime:'Select Date & Time',
	goods_img:'Thumbnail List',
	goods_pics:'Multi Thumbnail List',
	click_uploads:'Upload',
	uploads_explain:'Only jpg/png files can be uploaded, each upload no more than 500kb',
	last_week:'Last Week',
	last_month:'Last Month',
	last_three_month:'Last 3 Months',
	shelf_warning:'Product uploaded cannot be edited, confirm?',
	suretoremove:'Confirm removal?',
	action:'Action',
	edit:'Edit',
	delete:'Delete',
	live_url:'Live Stream URL',
	cancel:'Cancel',
	submit:'Confirm',
	dkj:'To Be Drawn',
	ykj:'Won',
	wzj:'Not Win Yet',
	isgetself:'Whether To Pick-Up',
	getmyself:'Self Pick-Up',
	getbyposter:'Shipping',
	ordersn:'Order S/N',
	buynumber:'Purchase Quantity',
	waitpay:'Unpaid',
	waitsend:'Paid',
	alreadysend:'Shipped',
	complated:'Completed',
	showaddr:'Address',
	sendgoods:'Ship Out',
	getgoods:'Self Pick-Up',
	send_c_msg:'Shipping Information',
	send_c_name:'Shipping Company',
	send_c_code:'Tracking Number',
	send_c_tel:'Shipping Company Contact',
	order_addr:'Order Address',
	copy:'Copy',
	suregetandcomplete:'Confirm that the user has received the product and completed this round?',
	cronscode:'LD Code',
	input_get_code:'Enter Winning Code',
	get_code:'Winning Code',
	obsetting: 'Setting',
}


export const cn = {
	refreshtime: '刷新时间',
	getrealname: '领取人姓名',
	goodsname: '商品名称',
	gosearch: '搜索',
	seconds:'秒',
	priceforeverytimes: '价格（每次/总价）',
	totalnumber: '数量（已/总）',
	begintimes: '上架时间',
	isover: '已结束',
	isnotover: '未结束',
	goover:'结束',
	cornsno: '夺宝编号',
	username: '用户名',
	createdtime: '购买时间',
	isprezzy: '是否中奖',
	isnotlucky: '未中奖',
	islucky: '中奖',
	goodstitle: '标题',
	language: '语言',
	begin_time: '开始日期',
	begin_end: '至',
	end_time: '结束日期',
	status:'状态',
	enabled:'正常',
	disabled:'隐藏',
	addgoods:'添加商品',
	goodsid:'编号',
	isuse:'期号',
	goodsicon:'图标',
	goodsprice:'价格（每份/总价）',
	eachnum:'每人可购买(份)',
	opentime:'开奖时间',
	isshow:'显示',
	gopulish:'发布活动',
	isshelf:'已发布',
	push_now:'立即发布',
	createdat:'添加时间',
	opencode:'开奖码',
	editgoods:'编辑商品',
	goods_title:'商品标题',
	goods_desc:'商品描述',
	total_price:'总价',
	pet_price:'单价',
	each_num:'每人限购',
	total_num:'总数量',
	lower_grade:'最低等级',
	choosetime:'选择日期时间',
	goods_img:'列表缩略图',
	goods_pics:'多图片列表',
	click_uploads:'点击上传',
	uploads_explain:'只能上传jpg/png文件，且不超过500kb',
	last_week:'最近一周',
	last_month:'最近一个月',
	last_three_month:'最近三个月',
	shelf_warning:'上架商品将无法更改其他信息，确认上架？',
	suretoremove:'确认移除',
	action:'操作',
	edit:'编辑',
	delete:'删除',
	live_url:'直播地址',
	cancel:'取消',
	submit:'确定',
	dkj:'待开奖',
	ykj:'已中奖',
	wzj:'未中奖',
	isgetself:'是否自取',
	getmyself:'自取',
	getbyposter:'邮寄',
	ordersn:'订单编号',
	buynumber:'购买数量',
	waitpay:'未支付',
	waitsend:'已支付',
	alreadysend:'已发货',
	complated:'已完成',
	showaddr:'地址',
	sendgoods:'发货',
	getgoods:'自取',
	send_c_msg:'物流信息',
	send_c_name:'物流公司',
	send_c_code:'物流编码',
	send_c_tel:'物流公司电话',
	order_addr:'订单地址',
	copy:'复制',
	suregetandcomplete:'确认用户已领取商品并完成此期 ?',
	cronscode:'夺宝码',
	input_get_code:'录入中奖码',
	get_code:'中奖码',
	obsetting: '设置',
}