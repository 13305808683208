import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import wsConnection from "./libs/wsstore";
import Element from 'element-ui'
import { PAGE } from '@/libs/const';
//import moment from 'moment'
import i18n from './i18n/i18n';
import 'default-passive-events'

import './assets/scss/element-variables.scss'
import './assets/iconfont/iconfont.css';
import './assets/scss/main.scss'
import './assets/scss/responsive.scss'

import mixin from '@/libs/mixin';
Vue.mixin(mixin);
import {httpget,httppost,httpput,httppatch,httpdelete,httpfile} from './libs/api'
import locale from 'element-ui/lib/locale/lang/zh-CN'
// import './styles.scss'

Vue.use(Element, { locale,size: 'small' })
/* eslint-disable */
Vue.config.productionTip = false;
router.beforeEach(async (to, from, next) => {
  // 登录， 不做验证，直接跳转到登录页面
  if (to.path == '/login' || to.path == '/test') {
    next();
  }
  // 如果没有token 跳转到登录页面 token令牌是唯一的验证依据
  if (store.getters.getToken === '' || store.getters.getToken === null) {
    router.push({path: '/login'}).catch(err => {err});
    return;
  }
  // 首页兼容
  if (to.path == '/' || to.path == '/index') {
    router.push({path: '/home'}).catch(err => {err});
    return;
  }
  store.dispatch('setDefaultMenu', to.path);
  //console.log('当前路径:', to.path);
  next();
});
new Vue({
  router,
  store,
  i18n,
  methods: {
    configure() {
      Vue.prototype.httpget   = httpget;
      Vue.prototype.httppost  = httppost;
      Vue.prototype.httpput   = httpput;
      Vue.prototype.httppatch = httppatch;
      Vue.prototype.httpdelete= httpdelete;
      Vue.prototype.httpfile  = httpfile;
      Vue.prototype.PAGE = PAGE;
      Vue.prototype.$ws = wsConnection;
      // Vue.prototype.$moment = moment;
      // Vue.prototype.URL = URL;
      // Vue.prototype.loading = false;
    }
  },
  created() {
    this.configure();
  },
  mounted() {
  },
  render: h => h(App)
}).$mount('#app')
