<template>
  <el-image :src="welcome" style="width: 100%;"></el-image>
</template>
<script>
  // @ is an alias to /src
  export default {
    name: 'home',
    data() {
      return {
        menus: [],
        welcome: require('../assets/img/welcome.jpg'),
      }
    },
    computed: {
      userinfo: {
        get: function() {
          return this.$store.getters.getAdminInfo;
        }
      },
    },
    methods:{
    },
    mounted() {
    }
  }
</script>
