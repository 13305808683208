export const en = {
	supershare: 'Top Media',
	submit: 'Submit',
	reset: 'Reset',
	choose: 'Choose',
	search: 'Search',
	notice: 'Notice',
	yes: 'Yes',
	no: 'No',
	sure: 'Sure',
	ok: 'OK',
	cancel: 'Cancel',
	canceled: 'Canceled',
	version: 'Version',
	version_v: ' Version ',
	remarks: 'Remarks',
	state: 'State',
	action: 'Action',
	add: 'Add',
	edit: 'Edit',
	delete: 'Delete',
	save: 'Save',
	enable: 'Enable',
	enables: 'Enables',
	disable: 'Disable',
	disables: 'Disables',
	areyousure: 'Are you sure?',
	keyword: 'Keyword',
	sum: 'Sum',
	male: 'Male',
	female: 'Female',
	yearsand: ' years and ',
	months: ' months ',
	export : 'Export',
	exportall: 'Export All',
	exportXml:'Export Xml',
	close: 'Close',
	created_at: 'creation time',
	updated_at: 'Change the time',
}

export const cn = {
	supershare: 'Top Media',
	submit: '确定',
	reset: '重置',
	choose: '选择',
	search: '搜索',
	notice: '提示',
	yes: '是',
	no: '否',
	sure: '确定',
	ok: '完成',
	cancel: '取消',
	canceled: '已取消',
	version: '版本',
	version_v: ' 版本 ',
	remarks: '备注',
	state: '状态',
	action: '操作',
	add: '添加',
	edit: '编辑',
	delete: '删除',
	save: '保存',
	enable:'正常',
	enables:'正常',
	disable: '禁用',
	disables: '禁用',
	areyousure: '确定吗?',
	keyword: '关键字',
	sum: '合计',
	male: '男',
	female: '女',
	yearsand: ' 岁 ',
	months: ' 月 ',
	export : '导出',
	exportall: '导出全部',
	exportXml:'导出Xml',
	close: '关闭',
	newexport:'导出Vip',
	created_at: '创建时间',
	updated_at: '修改时间',
}

export const th = {
	supershare: 'Top Media',
	submit: '确定',
	reset: '重置',
	choose: '选择',
	search: 'ค้นหา',
	notice: '提示',
	yes: '是',
	no: '否',
	sure: '确定',
	ok: '完成',
	cancel: '取消',
	canceled: '已取消',
	version: '版本',
	version_v: ' 版本 ',
	remarks: '备注',
	state: '状态',
	action: '操作',
	add: '添加',
	edit: '编辑',
	delete: '删除',
	save: '保存',
	enable:'正常',
	enables:'正常',
	disable: '禁用',
	disables: '禁用',
	areyousure: '确定吗?',
	keyword: '关键字',
	sum: '合计',
	male: '男',
	female: '女',
	yearsand: ' 岁 ',
	months: ' 月 ',
	export : 'ส่งออก',
	exportall: 'ส่งออกทั้งหมด',
	exportXml:'Export Xml',
	close: 'ปิด',
}
