import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { MessageBox } from 'element-ui';

axios.defaults.baseURL=process.env.VUE_APP_API_ROOT;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.interceptors.request.use(
    config => {
        if( window.location.pathname === 'login' || (window.location.pathname == '/test' && config.method == 'post')){
            return config;
        } else {
            config.headers['Authorization']=`${store.getters.getToken}` + '=vueroute='+`${router.currentRoute.path}`;
        }
        // if (window.location.pathname !== '/login' && (window.location.pathname == '/test' && config.method != 'post')) {
        //     config.headers['Authorization']=`${store.getters.getToken}` + '=vueroute='+`${router.currentRoute.path}`;
        // }
        // config.headers['Referer']= `${router.currentRoute.path}`;
        // if(config.url.indexOf('http://')===-1 && config.url.indexOf('https://')===-1){
        //     config.url = process.env.VUE_APP_API_ROOT + config.url;
        // }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

// /**
//  * 给URL添加额外参数 params必须是对象{key:value,key2:value2}
//  */
// const addUrlParam = (url, params) => {
//     if(!params) return url;
//     var path = url.split('#')[0];
//     if (/\?/g.test(path)) {
//         let extra = [];
//         params.forEach((name,value)=>{
//             if (/name=[-\w]{4,25}/g.test(path)) {
//                 path = path.replace(/name=[-\w]{4,25}/g, name + "=" + value);
//             } else {
//                 extra.push(name+"="+value);
//             }
//         })
//         if(extra){
//             path+='&'+extra.join('&');
//         }
//     }else{
//         let extra = [];
//         params.forEach((key,value)=>{
//             extra.push(key+"="+value);
//         })
//         if(extra){
//             path+='?'+extra.join('&');
//         }
//     }
//     if (url.split('#')[1]) {
//         url = path + '#' + url.split('#')[1];
//     } else {
//         url = path;
//     }
//     return url;
// }

const formatSuccessRes = (res) => {
    if(res.data.code<0){
        // store.commit('setAdminInfo', '');
        // store.commit('setToken', '');
        // store.dispatch('setPowerMap','');
        // store.dispatch('setMenus','');
        // console.log('res.data', res.data);
        axios.post('/system/errorlog/index', {datacontent: JSON.stringify(res.data)})
            .then(res => {}).catch(res => {});
        if(res.data.code<-1){ // -1 是没有权限的 不跳出去
            router.push({path: '/login'}).catch(err => {err});
            return false;
        }else{
            MessageBox({
                showClose: true,
                message: res.data.msg,
                type: 'error',
                offset: 100
            });
        }
    }
    return {
        code: res.data.code,
        msg: res.data.msg,
        data: res.data.data,
        wait: res.data.wait?res.data.wait:3,
        time: res.data.time?res.data.time:''
    };
};

const formatErrorRes = (res, returnmsg) => {
    if (res && res.stack && res.stack.indexOf('timeout') > -1) {
        // MessageBox({
        //     showClose: true,
        //     message: 'Request error. Please check the network or contact the administrator',
        //     type: 'error',
        //     offset: 100
        // });
    }
    return {
        code: res.status,
        msg: res.statusText,
        data: {}
    };
};

export const httpget = (url, params, isreturnmsg) => {
    return axios.get(url, {params: params})
        .then(res => {
        return formatSuccessRes(res);
    })
    .catch(res => {
        return formatErrorRes(res, isreturnmsg);
    });
};

export const httppost = (url, params, isreturnmsg) => {
    return axios.post(url, params)
        .then(res => {
        return formatSuccessRes(res);
    })
    .catch(res => {
        return formatErrorRes(res, isreturnmsg);
    });
};

export const httppatch = (url, params, isreturnmsg) => {
    return axios.patch(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httpput = (url, params, isreturnmsg) => {
    return axios.put(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res, isreturnmsg);
        });
};

export const httpdelete = (url, params,isreturnmsg) => {
    return axios.delete(url, params)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res,isreturnmsg);
        });
};

export const httpfile = (url, params,headers,isreturnmsg) => {
    let defaultheaders = {
        "Content-Type": "multipart/form-data",
        "Authorization":`${store.getters.getToken}` + '=vueroute='+`${router.currentRoute.path}`
    };
    if(headers){
        headers = Object.assign(defaultheaders, headers);
    }
    return axios.post(url, params,headers)
        .then(res => {
            return formatSuccessRes(res);
        })
        .catch(res => {
            return formatErrorRes(res,isreturnmsg);
        });
};

// import {post,fetch,patch,put} from './utils/http'
// //定义全局变量
// Vue.prototype.$post=post;
//vue3 的话  app.congig.globa.....
