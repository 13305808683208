import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 初始化用户信息
const defaultAdminInfo = {
  uid: 0,
  realname: '',
  role_id: '',
  role_name: '',
  menutype: 0 // 菜单样式 0 横条 1 左边栏
};

let storage = window.localStorage;

export default new Vuex.Store({
  state: {
    adminInfo: defaultAdminInfo, // 用户信息
    token: '',
    powerMap: [], // 权限图
    menus: [], // 菜单 和权限图不一样 权限图就是只是用来控制权限,
    defaultActiveMenu: '/home',
    serdomain: '',
    wsdomain: '',
    wsmsg: '',
    systemsetting: {},
    netStatus: 'offline', // 网络状态
    currLang: 'cn', //语言包 默认cn 中文 en 英文
    langlist: [],
  },
  getters: {
    // 获取用户信息
    getAdminInfo(state) {
      if (state.adminInfo.realname == '') {
        storage = storage ? storage : window.localStorage;
        try {
          let admininfo = storage.getItem('adminInfo');
          let token = storage.getItem('token');
          if (admininfo && token) {
            state.adminInfo = JSON.parse(admininfo);
            state.token = token;
          }
        } catch (e) {
          state.adminInfo = defaultAdminInfo;
        }
      }
      return state.adminInfo;
    },
    getSystemSetting(state) {
      if (Object.keys(state.systemsetting).length==0) {
        storage = storage ? storage : window.localStorage;
        try {
          let systemsetting = storage.getItem('systemsetting');
          if (systemsetting) {
            state.systemsetting = JSON.parse(systemsetting);
          }
        } catch (e) {
          state.systemsetting = {};
        }
      }
      return state.systemsetting;
    },
    // 获取Token
    getToken(state) {
      if (state.token == '') {
        storage = storage ? storage : window.localStorage;
        try {
          let token = storage.getItem('token');
          if (token != null) {
            state.token = token;
          }
        } catch (e) {
          state.token = '';
        }
      }
      return state.token;
    },

    getSerdomain(state) {
      if (state.serdomain == '') {
        storage = storage ? storage : window.localStorage;
        try {
          let serdomain = storage.getItem('serdomain');
          if (serdomain != null) {
            state.serdomain = serdomain;
          }else{
            state.serdomain = '';
          }
        } catch (e) {
          state.serdomain = '';
        }
      }
      return state.serdomain;
    },

    getWsdomain(state) {
      if (state.wsdomain == '') {
        storage = storage ? storage : window.localStorage;
        try {
          let wsdomain = storage.getItem('wsdomain');
          if (wsdomain != null) {
            state.wsdomain = wsdomain;
          }else{
            state.wsdomain = '';
          }
        } catch (e) {
          state.wsdomain = '';
        }
      }
      return state.wsdomain;
    },

    // 获取权限图
    getMenus(state) {
      storage = storage ? storage : window.localStorage;
      try {
        let menus = storage.getItem('menus');
        if (menus) {
          state.menus = JSON.parse(menus);
        }
      } catch (e) {
        state.menus = [];
      }
      return state.menus;
    },
    // 获取权限图
    getPowerMap(state) {
      if (Object.keys(state.powerMap).length == 0) {
        storage = storage ? storage : window.localStorage;
        try {
          let powerMap = storage.getItem('powerMap');
          if (powerMap) {
            state.powerMap = JSON.parse(powerMap);
          }
        } catch (e) {
          state.powerMap = [];
        }
      }
      return state.powerMap;
    },
    getDefaultMenu(state){
      return state.defaultActiveMenu;
    },
    getWsmsg(state){
      return state.wsmsg;
    },
    getNetStatus(state){
      if (state.netStatus == '') {
        storage = storage ? storage : window.localStorage;
        try {
          let netStatus = storage.getItem('netStatus');
          if (netStatus != null) {
            state.netStatus = netStatus;
          }else{
            state.netStatus = '';
          }
        } catch (e) {
          state.netStatus = '';
        }
      }
      return state.netStatus;
    },
    getCurrLang(state){
      storage = storage ? storage : window.localStorage;
      try {
        let currLang = storage.getItem('currLang');
        if (currLang != null) {
          state.currLang = currLang;
        }else{
          state.currLang = 'cn';
        }
      } catch (e) {
        state.currLang = 'cn';
      }
      return state.currLang;
    },
    getLanglist(state) {
      if (state.langlist == '') {
        storage = storage ? storage : window.localStorage;
        try {
          let langlist = storage.getItem('langlist');
          if (langlist) {
            state.langlist = JSON.parse(langlist);
          }
        } catch (e) {
          state.langlist = [];
        }
      }
      return state.langlist;
    },
  },
  mutations: {
    // 存储用户信息
    setAdminInfo(state, adminInfo) {
      state.adminInfo = adminInfo;
      storage = storage ? storage : window.localStorage;
      storage.setItem("adminInfo", JSON.stringify(adminInfo));
    },
    setSystemSetting(state, systemsetting) {
      state.systemsetting = systemsetting;
      storage = storage ? storage : window.localStorage;
      storage.setItem("systemsetting", JSON.stringify(systemsetting));
    },
    // 初始化用户信息
    initUserInfo(state) {
      state.adminInfo = defaultAdminInfo;
      storage = storage ? storage : window.localStorage;
      storage.setItem("adminInfo", JSON.stringify(defaultAdminInfo));
    },
    // 存储菜单
    setMenus(state, data) {
      state.menus = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("menus", JSON.stringify(data));
    },
    // 存储权限图
    setPowerMap(state, data) {
      state.powerMap = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("powerMap", JSON.stringify(data));
    },
    //存储Token
    setToken(state, data) {
      state.token = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("token", data);
    },
    setSerdomain(state, data) {
      state.serdomain = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("serdomain", data);
    },
    setWsdomain(state, data) {
      state.wsdomain = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("wsdomain", data);
    },
    setDefaultMenu(state, url) {
      state.defaultActiveMenu = url;
    },
    setWsmsg(state,data){
      state.wsmsg = data;
    },
    setNetStatus(state,data){
      state.netStatus = data;
      storage = storage ? storage : window.localStorage;
      storage.setItem("netStatus", data);
    },
    setCurrLang(state,data){
      state.currLang = data;
      let storage = window.localStorage;
      storage.setItem("currLang", data);
    },
    setLanglist(state, langlist) {
      state.langlist = langlist;
      storage = storage ? storage : window.localStorage;
      storage.setItem("langlist", JSON.stringify(langlist));
    },
  },
  actions: {
    // 存储用户信息
    setAdminInfo(context, adminInfo) {
      context.commit('setAdminInfo', adminInfo);
    },
    setSystemSetting(context, systemsetting) {
      context.commit('setSystemSetting', systemsetting);
    },
    // 初始化用户信息
    initUserInfo(context) {
      context.commit('initUserInfo');
    },
    setMenus(context, data) {
      context.commit('setMenus', data);
    },
    // 存储权限图
    setPowerMap(context, data) {
      context.commit('setPowerMap', data);
    },
    setToken(context, data) {
      context.commit('setToken', data);
    },
    setSerdomain(context, data) {
      context.commit('setSerdomain', data);
    },
    setWsdomain(context, data) {
      context.commit('setWsdomain', data);
    },
    setDefaultMenu(context, url) {
      context.commit('setDefaultMenu', url);
    },
    setWsmsg(context, data){
      context.commit('setWsmsg', data);
    },
    setNetStatus(context,data){
      context.commit('setNetStatus', data);
    },
    setCurrLang(context,data){
      context.commit('setCurrLang', data);
    },
    setLanglist(context,data){
      context.commit('setLanglist', data);
    }
  }
})
